body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8fbfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiInputBase-input.Mui-disabled {
  color: rgb(120,120,120);
}

.MuiFormHelperText-root {
  margin-bottom: 1em !important;
}

.MuiTable-root .MuiButton-label {
  white-space: nowrap;
}

.box {
  position: relative;
  background: #EEE;
}
.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#CFCFCF 0%, #CFCFCF 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #CFCFCF;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #CFCFCF;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #CFCFCF;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #CFCFCF;
}

.feed > .item {
  clear: both;
  max-width: 60%;
  float: left;
  margin-top: 8px;
}

.feed > .item[type=SelfMessage] {
  float: right;
  margin-top: 2px;
}

.feed > .item[type=SystemMessage] {
  width: 100%;
  max-width: unset;
}

.feed > .item > .bubble {
  padding: 8px;
  border-radius: 8px;
  background-color: #1aa3fc;
  color: white;
  white-space: pre-wrap;
}

.feed > .item > .bubble.sameAbove {
  border-top-right-radius: 0;
}

.feed > .item > .bubble.sameBelow {
  border-bottom-right-radius: 0;
}

.feed > .item[type=SelfMessage] > .bubble {
  background-color: #dddddd;
  float: right;
  color: rgba(0, 0, 0, 0.87);
}

.feed > .item > .from, .feed > .item > .to {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.50);
  padding: 2px 8px 8px 8px;
}

.feed > .item > .from {
  text-align: right;
  padding: 8px 8px 2px 8px;
}

.feed > .item > .time {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.50);
}

.feed > .item > .system {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.50);
}

.nyfty-expanded {
  margin-bottom: 0 !important;
}

.show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.show-scrollbar::-webkit-scrollbar:vertical {
  width: 11px;
}

.show-scrollbar::-webkit-scrollbar:horizontal {
  height: 11px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .3);
}

.list-item-markdown h1,
.list-item-markdown h2,
.list-item-markdown h3,
.list-item-markdown h4,
.list-item-markdown h5,
.list-item-markdown h6,
.list-item-markdown p,
.list-item-markdown blockquote {
  margin: 0.1em 0;
  font-weight: normal;
}
.list-item-markdown h4,
.list-item-markdown h5,
.list-item-markdown h6 {
  font-weight: normal;
}

.list-item-markdown p {
  font-size: 12px;
}

.list-item-markdown blockquote {
  border-left: 2px solid gray;
  padding-left: 1em;
}

/*.App {
  padding-top: 20px;
}*/

.top-bar {
  position: fixed;
  left: 0;
  right: 0;
  height: 36px;
  z-index: 100;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.top-bar.language-selector {
  background-color: #1aa3fc;
}
.top-bar.language-selector > div {
  position: fixed;
  top: 6px;
  right: 20px;
  height: 24px;
  color: white;
  border: 1px solid white;
  border-radius: 12px;
  padding-left: 6px;
  cursor: pointer;
}
.top-bar.language-selector > div > span {
  position: relative;
  top: -6px;
  padding-right: 2px;
}

.top-bar.home {
  background-color: #1d7595;
}
.top-bar.home > div {
  position: relative;
  top: 6px;
  cursor: pointer;
}
.top-bar.home > div > span {
  position: relative;
  top: -6px;
  padding-left: 2px;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

div.launcher > p {
  margin-top: 0;
}
div.launcher > p:last-child {
  margin-bottom: 0;
}

.login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin: auto;
  cursor: pointer;
  height: 40px;
  max-width: 250px;
  margin-top: 2px;
  border: 2px solid black;
  background-color: black;
  color: white;
}

p.MuiFormHelperText-root {
  margin: 0 !important;
}

.MuiDialog-root {
  z-index: 13000 !important;
}
.MuiPopover-root {
  z-index: 13000 !important;
}
div[role="tooltip"] {
  z-index: 13000 !important;
}

.shimmer-element {
  background: linear-gradient(-45deg, #ffffff 40%, #dddddd 50%, #ffffff 60%);
  background-size: 300%;
  animation-name: shimmer;
  animation-duration: 3500ms;
  animation-timing-function: linear;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes shimmer {
  0% {
    background-position-x: 100%;
  }
  20% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 0%;
  }
}
